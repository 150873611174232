import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Account } from '../../../account/account.models';
import { NotifyService } from '../../../common/services/notify.service';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { saveAccount } from '../../store/accounts/accounts.actions';
import {
  selectAccountsErrors,
  selectAccountsIsSubmittingFlag,
  selectLastlyCreatedAccount,
} from '../../store/accounts/accounts.selectors';
import { RegionsResource } from '../../../connections/resources/regions.resource';

@Component({
  selector: 'account-form',
  template: `
    <xp-form-validation type="Account" [name]="formName">
      <div class="account-form">
        <form class="modal-form-container" id="accountForm" name="accountForm" #form="ngForm" novalidate>
          <div class="account-form-body">
            <div class="form-group">
              <xp-form-group>
                <label for="name">{{ 'account.form.labels.name' | translate }}</label>
                <xp-input
                  type="text"
                  name="name"
                  id="name"
                  [(ngModel)]="item.name"
                  class="form-control account-name"
                ></xp-input>
              </xp-form-group>
              <small>{{ 'account.form.hints.name' | translate }}</small>
            </div>
            <div class="form-group">
              <label>{{ 'account.form.labels.region' | translate }}</label>
              <xp-form-group [validationDisabled]="true">
                <xp-select
                  class="form-control xp-select"
                  id="region-picker"
                  name="region-picker"
                  [(value)]="item.region"
                  [options]="regions"
                >
                </xp-select>
              </xp-form-group>
              <small [innerHTML]="'account.form.hints.region' | translate"></small>
            </div>
            <p class="alert alert-warning">{{ 'account.form.hints.trial' | translate }}</p>
          </div>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </form>
        <div class="modal-footer account-form-footer">
          <div class="modal-title-container">
            <h3 class="modal-title">{{ 'account.form.title' | translate }}</h3>
          </div>
          <xp-submit-button
            (click)="createAccount(item)"
            classNames="btn-lg btn-success pull-right"
            [createText]="'account.form.buttons.create' | translate"
            [isFormValid]="form.valid"
            [isFormSubmitting]="isSubmitting$ | async"
          ></xp-submit-button>
        </div>
      </div>
    </xp-form-validation>
  `,
})
export class AccountFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  @Output() created = new EventEmitter<Account>();
  item: Partial<Account> = {};
  @ViewChild('form') form: NgForm;
  formName = 'accountForm';
  successMessageText = 'account.form.success_message';
  isSubmitting$ = this.store.select(selectAccountsIsSubmittingFlag);
  regions = [];
  errors$ = this.store.select(selectAccountsErrors);
  errorTexts = [];
  newAccountItemSubscriber: Subscription;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private regionsResource: RegionsResource,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.regionsResource
      .query('', {
        sort: 'name',
        direction: 'asc',
        limit: '100',
      } as any)
      .subscribe((regions) => {
        this.regions = regions.map(({ id, name }) => ({
          text: name,
          value: id,
        }));

        this.item.region = (this.regions.find((region) => region.value === 'virginia') || this.regions[0]).value;
      });
  }

  createAccount(account: Partial<Account>) {
    this.store.dispatch(
      saveAccount({ account: { ...account }, shouldRedirect: this.router.url.includes('/launchpad') }),
    );

    this.newAccountItemSubscriber = this.isSubmitting$
      .pipe(
        filter((isSubmitting) => !isSubmitting),
        withLatestFrom(this.store.select(selectLastlyCreatedAccount)),
      )
      .subscribe(([, item]) => {
        if (item) {
          this.created.emit(item);
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.newAccountItemSubscriber) {
      this.newAccountItemSubscriber.unsubscribe();
    }
  }
}
