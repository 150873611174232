import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { escape } from 'lodash';
import {
  CloudStorageSourceComponentData,
  Connection,
  ConnectionSchemaField,
  RECORD_DELIMITER,
  RECORD_TYPE,
  Schema,
} from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { getConnectionSchema, setConnectionSchemaLoadingFlag } from '../../../connections/store/connections.actions';
import { getDataForSchemaImporter } from '../../helpers/schema.helpers';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { ConnectionTypeName, CloudStorageConnectionsQuery } from '../../../connections/connection.models';
import { VariablesEvaluationService } from '../../helpers/variables-evaluation.service';

const BEFORE_ACTION_OPTIONS = [
  {
    value: 'none',
    text: 'cloud-storage-source-editor.form.selects.before_action.options.process_all_files',
    translate: true,
  },
  {
    value: 'copy_manifest',
    text: 'cloud-storage-source-editor.form.selects.before_action.options.process_only',
    translate: true,
  },
  {
    value: 'copy',
    text: 'cloud-storage-source-editor.form.selects.before_action.options.copy_merge_and_process',
    translate: true,
  },
];

@Component({
  selector: 'cloud-storage-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
          <dynamic-connection [component]="rawComponent" *ngxPermissionsOnly="'dynamicConnection'"></dynamic-connection>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="database-source-editor">
            <xp-form-validation type="CloudStorageSource">
              <form class="cloud-storage-source-editor" name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div
                    class="col-md-6"
                    *ngIf="
                      rawComponent.connection.type !== ConnectionTypeName.sftp &&
                      rawComponent.connection.type !== ConnectionTypeName.ftps
                    "
                  >
                    <xp-form-group
                      *ngIf="
                        [
                          ConnectionTypeName.s3,
                          ConnectionTypeName.gs,
                          ConnectionTypeName.gsv2,
                          ConnectionTypeName.azureBlobStorage,
                        ].includes(rawComponent.connection.type)
                      "
                    >
                      <label for="bucket" *ngIf="hasBucket || hasContainer">
                        <span *ngIf="hasBucket">{{
                          'cloud-storage-source-editor.form.labels.bucket' | translate
                        }}</span>
                        <span *ngIf="hasContainer">{{
                          'cloud-storage-source-editor.form.labels.container' | translate
                        }}</span>
                      </label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="bucket"
                        id="bucket"
                        [ngModel]="rawComponent.bucket"
                        (ngModelChange)="onValueChange($event, 'bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.bucket-s3' | translate"
                        *ngIf="rawComponent.connection.type === ConnectionTypeName.s3"
                      ></xp-input>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="bucket_gc"
                        id="bucket"
                        [ngModel]="rawComponent.bucket"
                        (ngModelChange)="onValueChange($event, 'bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.bucket-gs' | translate"
                        *ngIf="
                          rawComponent.connection.type === ConnectionTypeName.gs ||
                          rawComponent.connection.type === ConnectionTypeName.gsv2
                        "
                      ></xp-input>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="container_azureblobstorage"
                        id="bucket"
                        [ngModel]="rawComponent.bucket"
                        (ngModelChange)="onValueChange($event, 'bucket')"
                        [placeholder]="'cloud-storage-source-editor.form.placeholders.container' | translate"
                        *ngIf="rawComponent.connection.type === ConnectionTypeName.azureBlobStorage"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <xp-form-group>
                      <label for="path">{{ 'cloud-storage-source-editor.form.labels.path' | translate }}</label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="path"
                        id="path"
                        [ngModel]="rawComponent.path"
                        (ngModelChange)="onValueChange($event, 'path')"
                        [placeholder]="pathPlaceholder | translate"
                        (inputChange)="onPathInputChange($event)"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <xp-form-group *ngIf="isZipInFileName">
                      <label for="zip_password">{{
                        'cloud-storage-source-editor.form.labels.zip_password' | translate
                      }}</label>

                      <xp-input
                        [ngModel]="rawComponent.zip_password"
                        id="zip_password"
                        name="zip_password"
                        type="password"
                        class="form-control"
                        (ngModelChange)="onValueChange($event, 'zip_password')"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="record_delimiter">{{
                        'cloud-storage-source-editor.form.labels.record_delimiter' | translate
                      }}</label>
                      <div class="btn-group btn-group-md btn-group-select">
                        <button
                          type="button"
                          value="new_line"
                          class="btn btn-default"
                          [ngClass]="{
                            'active btn-primary': rawComponent.record_delimiter === RECORD_DELIMITER.NEW_LINE,
                          }"
                          (click)="onValueChange(RECORD_DELIMITER.NEW_LINE, 'record_delimiter')"
                        >
                          {{ 'cloud-storage-source-editor.form.selects.record_delimiter.options.new_line' | translate }}
                        </button>
                        <button
                          type="button"
                          value="eof"
                          class="btn btn-default"
                          [ngClass]="{ 'active btn-primary': rawComponent.record_delimiter === RECORD_DELIMITER.EOF }"
                          (click)="onValueChange(RECORD_DELIMITER.EOF, 'record_delimiter')"
                        >
                          {{ 'cloud-storage-source-editor.form.selects.record_delimiter.options.eof' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="record_type">{{
                        'cloud-storage-source-editor.form.labels.record_type' | translate
                      }}</label>
                      <div class="btn-group btn-group-md btn-group-select record-type-select">
                        <button
                          type="button"
                          class="btn btn-default"
                          value="delimited"
                          [ngClass]="{ 'active btn-primary': rawComponent.record_type === RECORD_TYPE.DELIMITED }"
                          (click)="onValueChange(RECORD_TYPE.DELIMITED, 'record_type')"
                          *ngIf="rawComponent.record_delimiter !== RECORD_DELIMITER.EOF"
                        >
                          {{ 'cloud-storage-source-editor.form.selects.record_type.options.delimited' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          value="excel"
                          [ngClass]="{ 'active btn-primary': rawComponent.record_type === RECORD_TYPE.EXCEL }"
                          (click)="onValueChange(RECORD_TYPE.EXCEL, 'record_type')"
                          *ngIf="rawComponent.record_delimiter !== RECORD_DELIMITER.EOF && !isZipInFileName"
                        >
                          {{ 'cloud-storage-source-editor.form.selects.record_type.options.excel' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          value="json"
                          [ngClass]="{ 'active btn-primary': rawComponent.record_type === RECORD_TYPE.JSON }"
                          (click)="onValueChange(RECORD_TYPE.JSON, 'record_type')"
                        >
                          {{ 'cloud-storage-source-editor.form.selects.record_type.options.json' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          value="raw"
                          [ngClass]="{ 'active btn-primary': rawComponent.record_type === RECORD_TYPE.RAW }"
                          (click)="onValueChange(RECORD_TYPE.RAW, 'record_type')"
                        >
                          {{ 'cloud-storage-source-editor.form.selects.record_type.options.raw' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          value="raw"
                          [ngClass]="{ 'active btn-primary': rawComponent.record_type === RECORD_TYPE.XML }"
                          (click)="onValueChange(RECORD_TYPE.XML, 'record_type')"
                          *ngIf="rawComponent.record_delimiter === RECORD_DELIMITER.EOF"
                        >
                          {{ 'cloud-storage-source-editor.form.selects.record_type.options.xml' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          value="raw"
                          [ngClass]="{ 'active btn-primary': rawComponent.record_type === RECORD_TYPE.PARQUET }"
                          (click)="onValueChange(RECORD_TYPE.PARQUET, 'record_type')"
                          *ngIf="rawComponent.record_delimiter !== RECORD_DELIMITER.EOF && !isZipInFileName"
                        >
                          {{ 'cloud-storage-source-editor.form.selects.record_type.options.parquet' | translate }}
                        </button>
                      </div>
                    </div>

                    <xp-form-group *ngIf="rawComponent.record_type === RECORD_TYPE.EXCEL">
                      <div *ngIf="!rawComponent.ingest_large_excel_file">
                        <label for="path">{{ 'cloud-storage-source-editor.form.labels.sheet_name' | translate }}</label>
                        <xp-input
                          type="text"
                          class="form-control"
                          name="sheet_name"
                          id="sheet_name"
                          [ngModel]="rawComponent.sheet_name"
                          (ngModelChange)="onValueChange($event, 'sheet_name')"
                          [placeholder]="'cloud-storage-source-editor.form.placeholders.sheet_name' | translate"
                        ></xp-input>
                      </div>
                    </xp-form-group>
                    <xp-form-group *ngIf="rawComponent.record_type === RECORD_TYPE.EXCEL">
                      <xp-input-checkbox
                        [ngModel]="rawComponent.ingest_large_excel_file"
                        (ngModelChange)="onValueChange($event, 'ingest_large_excel_file')"
                        name="include_empty_rows"
                        [hint]="'cloud-storage-source-editor.form.hints.ingest_large_excel_file' | translate"
                        matTooltipPosition="right"
                        [labelText]="'cloud-storage-source-editor.form.labels.ingest_large_excel_file' | translate"
                      ></xp-input-checkbox>
                    </xp-form-group>
                    <cloud-storage-delimiter
                      *ngIf="
                        rawComponent.record_type === RECORD_TYPE.DELIMITED ||
                        rawComponent.record_type === RECORD_TYPE.EXCEL ||
                        rawComponent.record_type === RECORD_TYPE.PARQUET
                      "
                      [settings]="rawComponent.record_settings"
                      [rawComponent]="rawComponent"
                      [component]="component"
                      [lineInside]="true"
                    ></cloud-storage-delimiter>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group" *ngIf="hasXMLPath">
                      <label for="json_path">Base record XML key</label>
                      <xp-input
                        type="text"
                        name="xml_path"
                        id="xml_path"
                        class="form-control"
                        [ngModel]="rawComponent.xml_path"
                        (ngModelChange)="onValueChange($event, 'xml_path')"
                      ></xp-input>
                    </div>
                    <json-path
                      [path]="rawComponent.json_path"
                      (valueChange)="onValueChange($event, 'json_path')"
                      *ngIf="hasJsonPath"
                    ></json-path>
                    <xp-form-group *ngIf="hasPreProcess" [hidden]="rawComponent.record_type === RECORD_TYPE.EXCEL">
                      <label for="before_action">{{
                        'cloud-storage-source-editor.form.labels.before_action' | translate
                      }}</label>
                      <xp-select
                        [value]="rawComponent.before_action"
                        [options]="beforeActionOptions"
                        [preventEmpty]="true"
                        id="before_action"
                        name="before_action"
                        class="form-control xp-select"
                        (valueChange)="onValueChange($event, 'before_action')"
                      ></xp-select>
                      <small *ngIf="rawComponent.before_action === 'copy_manifest'">{{
                        'cloud-storage-source-editor.form.labels.maintains_manifest' | translate
                      }}</small>
                    </xp-form-group>
                    <xp-form-group
                      *ngIf="
                        (rawComponent.before_action === 'copy' || rawComponent.before_action === 'copy_manifest') &&
                        rawComponent.record_type !== RECORD_TYPE.PARQUET
                      "
                    >
                      <label for="char_encoding">{{
                        'cloud-storage-source-editor.form.labels.char_encoding' | translate
                      }}</label>

                      <xp-select
                        [value]="rawComponent.char_encoding"
                        [options]="charEncodings"
                        [preventEmpty]="true"
                        id="char_encoding"
                        name="char_encoding"
                        class="form-control xp-select"
                        (valueChange)="onValueChange($event, 'char_encoding')"
                      ></xp-select>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row" *ngIf="hasPreProcess && rawComponent.before_action === 'copy_manifest'">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="manifest_path_connection">{{
                        'cloud-storage-source-editor.form.labels.files_manifest_path_connection' | translate
                      }}</label>

                      <xp-select-picker-editable
                        id="manifest_path_connection"
                        type="connection"
                        [value]="rawComponent.manifest_connection"
                        placeholder="Select connection"
                        emptyPlaceholder="Connections list is empty"
                        [params]="connectionQuery"
                        (valueChange)="onValueChange($event, 'manifest_connection')"
                        [hideNew]="true"
                        (createNew)="onCreateNewConnection($event)"
                      ></xp-select-picker-editable>
                    </div>
                    <xp-form-group>
                      <label for="manifest_path"
                        >{{ 'cloud-storage-source-editor.form.labels.files_manifest_path' | translate }}
                        <i
                          class="fa fa-exclamation-circle"
                          matTooltip="Make sure to specify a .gz filename. Xplenty creates the file on first job run."
                          matTooltipPosition="after"
                          matTooltipClass="after"
                        ></i
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        name="manifest_path"
                        id="manifest_path"
                        [ngModel]="rawComponent.manifest_path"
                        (ngModelChange)="onValueChange($event, 'manifest_path')"
                        placeholder="bucket/path/to/manifest/file.gz"
                      />
                    </xp-form-group>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" (activate)="activateSchemaStep()" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            [ignoreFieldNameValidation]="true"
            [errorMessage]="schemaImporterErrorMessage"
            (updateValidation)="updateSchemaValidation($event)"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class CloudStorageSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: CloudStorageSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;
  ConnectionTypeName = ConnectionTypeName;
  RECORD_TYPE = RECORD_TYPE;
  RECORD_DELIMITER = RECORD_DELIMITER;
  schemaImporterErrorMessage = '';

  isSchemaValid = true;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});

  beforeActionOptions = BEFORE_ACTION_OPTIONS;
  charEncodings = [
    {
      value: 'utf-8',
      text: this.translate.instant('cloud-storage-source-editor.form.selects.char_encoding.options.utf_8'),
    },
    {
      value: 'utf-16',
      text: this.translate.instant('cloud-storage-source-editor.form.selects.char_encoding.options.utf_16'),
    },
    {
      value: 'shift-jis',
      text: this.translate.instant('cloud-storage-source-editor.form.selects.char_encoding.options.shift_jis'),
    },
    {
      value: 'cp932',
      text: this.translate.instant('cloud-storage-source-editor.form.selects.char_encoding.options.cp932'),
    },
  ];
  hasBucket = false;
  hasContainer = false;
  hasPreProcess = false;
  hasJsonPath = false;
  hasXMLPath = false;
  isZipInFileName = false;
  connectionQuery = {
    type: CloudStorageConnectionsQuery,
  };
  isBaseComponentStepActivated = false;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private authGuard: AuthorizationGuard,
    private variablesEvaluationService: VariablesEvaluationService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    if (this.rawComponent.source_path_field_alias) {
      // schema importer selected fields variable
      this.onFieldsChange([
        ...this.rawComponent.schema.fields,
        {
          name: 'file_path',
          alias: this.rawComponent.source_path_field_alias,
          data_type: 'string',
          id: uuidv4(),
          editable: true,
        },
      ]);
    }

    if (
      this.rawComponent.before_action === 'copy' &&
      this.rawComponent.manifest_connection &&
      this.rawComponent.manifest_connection.type
    ) {
      this.onValueChange('copy_manifest', 'before_action');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };

    if (changes.rawComponent) {
      const newComponent = changes.rawComponent.currentValue;
      const oldComponent = changes.rawComponent.previousValue;
      if (
        newComponent.record_delimiter === RECORD_DELIMITER.EOF &&
        oldComponent?.record_delimiter === RECORD_DELIMITER.NEW_LINE &&
        newComponent.record_type === RECORD_TYPE.DELIMITED
      ) {
        this.onValueChange(RECORD_TYPE.JSON, 'record_type');
      }

      this.hasJsonPath =
        newComponent.record_delimiter === RECORD_DELIMITER.EOF && newComponent.record_type === RECORD_TYPE.JSON;
      this.hasXMLPath =
        newComponent.record_delimiter === RECORD_DELIMITER.EOF && newComponent.record_type === RECORD_TYPE.XML;

      if (newComponent.record_type === RECORD_TYPE.EXCEL) {
        this.beforeActionOptions = [BEFORE_ACTION_OPTIONS[0]];
      }
      if (newComponent.record_type === RECORD_TYPE.PARQUET) {
        this.beforeActionOptions = [BEFORE_ACTION_OPTIONS[0], BEFORE_ACTION_OPTIONS[1]];
      } else {
        this.beforeActionOptions = BEFORE_ACTION_OPTIONS;
      }

      if (newComponent?.connection?.type) {
        switch (newComponent?.connection?.type) {
          case ConnectionTypeName.rackspace:
          case ConnectionTypeName.softlayer:
          case ConnectionTypeName.swift:
            this.hasContainer = true;
            this.hasBucket = false;
            this.hasPreProcess = false;
            break;
          case ConnectionTypeName.sftp:
          case ConnectionTypeName.ftps:
            this.hasContainer = false;
            this.hasBucket = false;
            this.hasPreProcess = true;
            break;
          case ConnectionTypeName.hdfs:
            this.hasContainer = false;
            this.hasBucket = false;
            this.hasPreProcess = false;
            break;
          case ConnectionTypeName.s3:
          case ConnectionTypeName.gs:
          case ConnectionTypeName.gsv2:
            this.hasContainer = false;
            this.hasBucket = true;
            this.hasPreProcess = true;
            break;
          case ConnectionTypeName.azureBlobStorage:
            this.hasContainer = true;
            this.hasBucket = false;
            this.hasPreProcess = true;
            break;
          default:
            this.hasContainer = false;
            this.hasBucket = false;
            this.hasPreProcess = false;
            break;
        }
      }
    }

    if (
      changes.rawComponent?.currentValue?.dynamic_connection !== changes.rawComponent?.previousValue?.dynamic_connection
    ) {
      if (this.rawComponent.dynamic_connection) {
        if (!this.connectionStep.tags.find((tag) => tag.name === this.component.dynamic_connection)) {
          this.connectionStep.tags = [
            ...this.connectionStep.tags,
            {
              name: this.component.dynamic_connection,
            },
          ];
        }
      } else {
        this.connectionStep.tags = this.connectionStep.tags.filter(
          (tag) => tag.name !== changes.component.previousValue.dynamic_connection,
        );
      }
      this.connectionStep = { ...this.connectionStep };
    }

    if (
      changes.rawComponent?.currentValue?.connection?.id !== changes.rawComponent?.previousValue?.connection?.id &&
      !changes.rawComponent?.firstChange
    ) {
      this.activateSchemaStep({ hardRefresh: true });
    }
  }

  get pathPlaceholder(): string {
    switch (this.rawComponent.connection?.type) {
      case ConnectionTypeName.s3:
      case ConnectionTypeName.azureBlobStorage:
        return 'cloud-storage-source-editor.form.placeholders.path-s3';
      case ConnectionTypeName.gs:
      case ConnectionTypeName.gsv2:
        return 'cloud-storage-source-editor.form.placeholders.path-gs';
      case ConnectionTypeName.sftp:
        return 'cloud-storage-source-editor.form.placeholders.path-sftp';
      default:
        return 'cloud-storage-source-editor.form.placeholders.path';
    }
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    this.isSchemaValid = isSchemaValid;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  activateSchemaStep({ hardRefresh = false } = {}) {
    if (this.rawComponent.connection?.id) {
      this.store.dispatch(setConnectionSchemaLoadingFlag({ value: true }));
      const schemaRequest = getDataForSchemaImporter(
        this.rawComponent,
        this.component.componentType,
        this.authGuard.account.account_id,
      );
      this.variablesEvaluationService.interpolateVariablesInData(schemaRequest).subscribe({
        next: (schemaRequestData) => {
          this.store.dispatch(
            getConnectionSchema({
              connectionId: this.rawComponent.connection?.id,
              connectionType: this.rawComponent.connection?.type,
              schemaRequestData,
              hardRefresh,
            }),
          );
        },
        error: (error) => {
          this.store.dispatch(setConnectionSchemaLoadingFlag({ value: false }));
          this.schemaImporterErrorMessage = error;
        },
      });
    }
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    if (this.rawComponent.dynamic_connection) {
      this.connectionStep.tags = [...this.connectionStep.tags, { name: this.rawComponent.dynamic_connection }];
    }

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    if (this.rawComponent[key] === value) {
      return;
    }

    const newComponent: any = { ...this.rawComponent, [key]: value };

    delete newComponent.schema;

    if (
      !newComponent.manifest_connection &&
      newComponent.manifest_path &&
      newComponent.before_action !== 'copy_manifest'
    ) {
      newComponent.manifest_connection = newComponent.connection;
    }

    if (newComponent.before_action !== 'copy_manifest') {
      newComponent.manifest_connection = {};
      newComponent.manifest_path = '';
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { ...newComponent },
      }),
    );
    this.store.dispatch(updateComponent({ component: newComponent }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { schema: { ...this.rawComponent.schema, fields } },
      }),
    );
  }

  onPathInputChange({ value }) {
    this.isZipInFileName = value?.toLowerCase().endsWith('.zip');
    if (
      this.isZipInFileName &&
      (this.rawComponent.record_type === RECORD_TYPE.EXCEL || this.rawComponent.record_type === RECORD_TYPE.PARQUET)
    ) {
      this.onValueChange(RECORD_TYPE.DELIMITED, 'record_type');
    }

    if (this.rawComponent.connection.type === ConnectionTypeName.sftp) {
      if (value && value[0] !== '/') {
        this.onValueChange(`/${value}`, 'path');
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
