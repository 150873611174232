import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CodeMirror from 'codemirror';

@Component({
  selector: 'code-editor-dialog',
  template: `
    <div id="code-editor-dialog">
      <div class="modal-header">
        <h3 class="title">{{ data.title }}</h3>
      </div>
      <div class="modal-body">
        <textarea
          class="form-control"
          id="code-editor-dialog-textarea"
          [ngModel]="data.value"
          [attr.name]="data.name"
        ></textarea>
      </div>
      <footer class="modal-footer">
        <button data-dismiss="modal" class="cancel btn btn-lg btn-primary" (click)="close()">Save and close</button>
      </footer>
    </div>
  `,
})
export class CodeEditorDialogComponent {
  textAreaElement: HTMLTextAreaElement;
  editor = null;
  value = '';

  constructor(
    public dialogRef: MatDialogRef<CodeEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      value: string;
      tables: string[];
      options: any;
      name: string;
      cursorPosition: CodeMirror.Position;
      title: string;
    },
    private elementRef: ElementRef,
  ) {}

  ngAfterViewInit() {
    this.textAreaElement = this.elementRef.nativeElement.querySelector('textarea');

    this.initEditor();
  }

  initEditor() {
    const oldCodeMirror = document.querySelector('#code-editor-dialog .CodeMirror');

    if (oldCodeMirror) {
      oldCodeMirror.parentElement.removeChild(oldCodeMirror);
    }

    setTimeout(() => {
      const defaultOptions = {};

      const options = { ...defaultOptions, ...(this.data.options || {}) };

      this.editor = CodeMirror.fromTextArea(this.textAreaElement, options);

      if (this.data.value) this.editor.setValue(this.data.value);

      this.editor.focus();

      if (this.data.cursorPosition) {
        this.editor.setCursor(this.data.cursorPosition);
      }

      // Enable auto hints
      this.editor.on('keyup', (cm: CodeMirror.Editor, event: KeyboardEvent) => {
        if (
          !cm.state.completionActive &&
          !event.ctrlKey &&
          !event.altKey &&
          !event.metaKey &&
          /^[a-zA-Z.]$/.test(event.key)
        ) {
          CodeMirror.commands.autocomplete(cm, null, { completeSingle: false });
        }
      });

      this.editor.on('change', (cm) => {
        this.value = cm.getValue();

        this.textAreaElement.dispatchEvent(
          new Event('input', {
            bubbles: true,
            cancelable: true,
          }),
        );
      });

      this.editor.on('blur', (cm) => {
        this.value = cm.getValue();
      });
    }, 100);
  }

  // eslint-disable-next-line class-methods-use-this
  getMetaKey() {
    return navigator.userAgent.indexOf('Mac OS X') !== -1 ? 'Cmd' : 'Ctrl';
  }

  close() {
    if (this.editor) {
      this.editor.toTextArea();
    }
    this.dialogRef.close({ value: this.value, cursorPosition: this.editor.getCursor() });
  }
}
