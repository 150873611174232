import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { Connection, ConnectionSchemaField, SalesforceSourceComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import {
  getConnectionSchema,
  getConnectionSchemaResponse,
  setConnectionSchemaLoadingFlag,
} from '../../../connections/store/connections.actions';
import { DesignerSchemaFieldI } from '../../models/designer-schema-field.model';
import { getDataForSchemaImporter } from '../../helpers/schema.helpers';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { SALESFORCE_API_VERSION, SALESFORCE_API_VERSIONS } from '../../../constants/salesforce_api_versions';
import { VariablesEvaluationService } from '../../helpers/variables-evaluation.service';

@Component({
  selector: 'salesforce-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="salesforce-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::SalesforceSourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="alert alert-warning" *ngIf="errorMessage">
                  <button type="button" class="close" (click)="errorMessage = null">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span>{{ errorMessage }}</span>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="api_version">{{
                        'salesforce-source-editor.form.labels.api_version' | translate
                      }}</label>
                      <xp-select
                        name="api_version"
                        id="api_version"
                        [value]="rawComponent.api_version"
                        [options]="apiVersions"
                        [preventEmpty]="true"
                        class="form-control xp-select"
                        (valueChange)="onApiVersionChange($event)"
                        emptyPlaceholder="No api version found"
                      ></xp-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <label for="access_mode">{{
                      'salesforce-source-editor.form.labels.access_mode' | translate
                    }}</label>
                    <div class="btn-group btn-group-md btn-group-select">
                      <button
                        type="button"
                        class="btn btn-default"
                        value="object"
                        name="access_mode"
                        [ngClass]="{ 'active btn-primary': rawComponent.access_mode === 'object' }"
                        (click)="onValueChange('object', 'access_mode')"
                      >
                        {{ 'salesforce-source-editor.form.labels.object' | translate }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-default"
                        value="query"
                        name="access_mode"
                        [ngClass]="{ 'active btn-primary': rawComponent.access_mode === 'query' }"
                        (click)="onValueChange('query', 'access_mode')"
                      >
                        {{ 'salesforce-source-editor.form.labels.query' | translate }}
                      </button>
                    </div>
                  </div>
                  <div [hidden]="rawComponent.access_mode !== 'object'">
                    <div class="col-sm-12">
                      <salesforce-object-picker
                        [rawComponent]="rawComponent"
                        [component]="component"
                        (fieldsLoadStart)="onFieldsLoadStart()"
                        (fieldsLoadEnd)="onFieldsLoadEnd($event)"
                      ></salesforce-object-picker>
                      <xp-form-group>
                        <label for="where_clause"
                          >{{ 'salesforce-source-editor.form.labels.where_clause' | translate }}
                        </label>
                        <code-editor
                          [value]="rawComponent.where_clause"
                          [options]="bodyDataOptions"
                          name="where_clause"
                          (valueChange)="onValueChange($event, 'where_clause')"
                        ></code-editor>
                        <small
                          >Read more about SOQL condition expression syntax
                          <a
                            href="https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql_select_conditionexpression.htm"
                            target="_blank"
                            >here</a
                          >
                        </small>
                      </xp-form-group>
                    </div>
                    <div class="col-sm-6">
                      <xp-form-group [validationDisabled]="true">
                        <label for="source_action">{{
                          'salesforce-source-editor.form.labels.source_action' | translate
                        }}</label>
                        <xp-select
                          class="form-control xp-select"
                          name="source_action"
                          id="source_action"
                          [value]="rawComponent.source_action"
                          [options]="sourceActions"
                          (valueChange)="onValueChange($event, 'source_action')"
                        >
                        </xp-select>
                      </xp-form-group>
                    </div>
                  </div>
                  <div [ngClass]="{ 'is-code-editor-invisible': rawComponent.access_mode !== 'query' }">
                    <xp-form-group class="col-md-12" [validationDisabled]="rawComponent.access_mode !== 'query'">
                      <label for="query">{{ 'salesforce-source-editor.form.labels.query' | translate }}</label>
                      <code-editor
                        [value]="rawComponent.query"
                        [options]="queryEditorOptions"
                        name="query"
                        (valueChange)="onValueChange($event, 'query')"
                      ></code-editor>
                      <small
                        >Read more about SOQL syntax
                        <a
                          href="https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql.htm"
                          target="_blank"
                          >here</a
                        >
                      </small>
                    </xp-form-group>
                  </div>
                  <div class="form-group">
                    <div class="col-md-12 chunk-size" *ngIf="rawComponent.api_version !== SALESFORCE_API_VERSION.V2">
                      <xp-input-checkbox
                        [ngModel]="rawComponent.is_chunk_size_enabled"
                        (ngModelChange)="onIsChunkSizeEnabledChange($event)"
                        [labelText]="'salesforce-source-editor.form.labels.chunk_size_checkbox' | translate"
                        name="is_chunk_size_enabled"
                      ></xp-input-checkbox>
                    </div>
                    <xp-form-group
                      class="col-md-6 chunk-size"
                      *ngIf="
                        rawComponent.is_chunk_size_enabled && rawComponent.api_version !== SALESFORCE_API_VERSION.V2
                      "
                    >
                      <label for="chunk_size">{{
                        'salesforce-source-editor.form.labels.chunk_size' | translate
                      }}</label>
                      <input
                        type="number"
                        class="form-control"
                        name="chunk_size"
                        id="chunk_size"
                        [ngModel]="rawComponent.chunk_size"
                        (ngModelChange)="onValueChange($event, 'chunk_size')"
                        placeholder="{{ 'salesforce-source-editor.form.labels.chunk_size' | translate }}"
                        [disabled]="!rawComponent.is_chunk_size_enabled"
                      />
                      <div class="error-message" *ngIf="form.controls.chunk_size && !form.controls.chunk_size.valid">
                        <span>{{ 'salesforce-source-editor.form.labels.chunk_size_error' | translate }}</span>
                      </div>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6" *ngIf="rawComponent.api_version === SALESFORCE_API_VERSION.V2">
                    <div class="form-group">
                      <label for="max_records"
                        >{{ 'salesforce-source-editor.form.labels.max_records' | translate }}
                        <i
                          class="fa fa-exclamation-circle"
                          [matTooltip]="'salesforce-source-editor.form.hints.max_records' | translate"
                          matTooltipPosition="after"
                          matTooltipClass="after"
                        ></i
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        name="max_records"
                        id="max_records"
                        [ngModel]="rawComponent.max_records"
                        (ngModelChange)="onValueChange($event, 'max_records')"
                        [ngClass]="{ 'ng-invalid': !isMaxRecordsInputValid }"
                        placeholder="{{ 'salesforce-source-editor.form.placeholders.max_records' | translate }}"
                      />
                      <div class="error-message" *ngIf="maxRecordsErrorMessage">
                        <span>{{ maxRecordsErrorMessage }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" (activate)="activateSchemaStep()" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [isConnectionSchemaLoading]="areFieldsLoading"
            [component]="component"
            [rawComponent]="rawComponent"
            [errorMessage]="schemaImporterErrorMessage"
            (updateValidation)="updateSchemaValidation($event)"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class SalesforceSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges, OnDestroy {
  @Input() rawComponent: SalesforceSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;
  schemaImporterErrorMessage = '';

  apiVersions = SALESFORCE_API_VERSIONS;
  SALESFORCE_API_VERSION = SALESFORCE_API_VERSION;
  isSchemaValid = true;
  isFormValid = true;
  validationChangeSubscription: Subscription;
  schemas = [];
  idOptions = [];
  schemaReady = false;
  areFieldsLoading = false;
  fields = [];
  idFieldErrorMsg = '';
  errorMessage = '';
  isBaseComponentStepActivated = false;
  isMaxRecordsInputValid = true;
  maxRecordsErrorMessage = '';

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});

  sourceActions = [
    { text: 'Bulk query', value: 'query' },
    { text: 'Bulk query all', value: 'queryAll' },
  ];
  bodyDataOptions = {
    lineWrapping: true,
    lineNumbers: true,
    mode: 'text/x-sql',
    placeholder: this.translate.instant('salesforce-source-editor.form.placeholders.where_clause'),
  };
  queryEditorOptions = {
    placeholder: 'SELECT field FROM object',
  };

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private authGuard: AuthorizationGuard,
    private variablesEvaluationService: VariablesEvaluationService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    window['access_mode_table'] = this.rawComponent.access_mode === 'table';
    window['access_mode_query'] = this.rawComponent.access_mode === 'query';
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };

    if (changes.rawComponent?.currentValue?.access_mode !== changes.rawComponent?.previousValue?.access_mode) {
      if ((this.rawComponent as SalesforceSourceComponentData).access_mode === 'object') {
        this.onValueChange(true, 'disableSchemaLoad');
        this.onValueChange(null, 'service_type');
      } else {
        this.onValueChange(false, 'disableSchemaLoad');
        this.onValueChange('file_type', 'service_type');
      }
    }

    if (
      changes.rawComponent?.currentValue?.is_chunk_size_enabled !==
      changes.rawComponent?.previousValue?.is_chunk_size_enabled
    ) {
      if ((this.rawComponent as SalesforceSourceComponentData).is_chunk_size_enabled && !this.rawComponent.chunk_size) {
        this.onValueChange(100000, 'chunk_size');
      }
    }
  }

  onApiVersionChange(apiVersion: string) {
    this.onValueChange(apiVersion, 'api_version');
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  onIsChunkSizeEnabledChange(value: boolean) {
    this.onValueChange(value, 'is_chunk_size_enabled');

    if (!value) {
      this.onValueChange(undefined, 'chunk_size');
    }
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    this.isSchemaValid = isSchemaValid;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid && this.isMaxRecordsInputValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid && this.isMaxRecordsInputValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  validateMaxRecords(value: string) {
    const isValid = !value || !isNaN(+value) || value[0] === '$';

    this.isMaxRecordsInputValid = isValid;
    this.maxRecordsErrorMessage = isValid
      ? ''
      : this.translate.instant('salesforce-source-editor.form.errors.max_records');
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid && this.isMaxRecordsInputValid };
  }

  onValueChange(value: any, key: string) {
    if (key === 'max_records') {
      this.validateMaxRecords(value);
    }

    if (key === 'access_mode') {
      window['access_mode_table'] = value === 'table';
      window['access_mode_query'] = value === 'query';
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { schema: { ...this.rawComponent.schema, fields } },
      }),
    );
  }

  onFieldsLoadStart() {
    this.schemaReady = false;
    this.schemaImporterStep.lock = false;
    this.areFieldsLoading = true;
  }

  onFieldsLoadEnd({ fields, access_mode }) {
    const destinationSchema: any = {};

    destinationSchema.id = 'salesforce_fields';
    destinationSchema.name = 'salesforce_fields';
    destinationSchema.fields = [];

    this.idOptions = [];

    fields.forEach((field) => {
      destinationSchema.fields.push({
        name: field.name,
      });
      if (field && field.is_keyable) {
        this.idOptions.push({ text: field.name, value: field.name });
      }
    });

    this.schemas = [this.parentSchemas[0], destinationSchema];
    this.fields = fields;

    this.schemaReady = true;
    this.schemaImporterStep.lock = false;

    this.checkUpsertKey(fields);

    this.areFieldsLoading = false;

    this.store.dispatch(
      getConnectionSchemaResponse({
        connectionSchema: fields as ConnectionSchemaField[],
        connectionSchemaData: { data: [], fields: [] },
        access_mode,
      }),
    );
  }

  checkUpsertKey(fields) {
    if (this.rawComponent.operation_type === 'upsert' && this.rawComponent.id_field) {
      const upsertKeyRecord = fields.find((field) => field.name === this.rawComponent.id_field);
      if (!upsertKeyRecord) {
        this.idFieldErrorMsg = this.translate.instant('salesforce-destination-editor.form.errors.id_field', {
          field: this.rawComponent.id_field,
        });
        this.onValueChange('', 'id_field');
        setTimeout(() => {
          if (this.form.controls.id_field) {
            this.form.controls.id_field.markAsDirty();
          }
        });
      }
    }
  }

  activateSchemaStep() {
    if (this.rawComponent.connection?.id && this.rawComponent.access_mode === 'query') {
      this.store.dispatch(setConnectionSchemaLoadingFlag({ value: true }));
      const schemaRequest = getDataForSchemaImporter(
        this.rawComponent,
        this.component.componentType,
        this.authGuard.account.account_id,
      );

      this.variablesEvaluationService.interpolateVariablesInData(schemaRequest).subscribe({
        next: (schemaRequestData) => {
          this.store.dispatch(
            getConnectionSchema({
              connectionId: this.rawComponent.connection?.id,
              connectionType: this.rawComponent.connection?.type,
              schemaRequestData,
              access_mode: this.rawComponent.access_mode,
            }),
          );
        },
        error: (error) => {
          this.store.dispatch(setConnectionSchemaLoadingFlag({ value: false }));
          this.schemaImporterErrorMessage = error;
        },
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }

    window['access_mode_table'] = false;
    window['access_mode_query'] = false;
  }
}
