import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  Inject,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { ConnectionSchemaField, DatabaseSourceComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { AnyConnection } from '../../../connections/connection.models';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { getConnectionSchema, setConnectionSchemaLoadingFlag } from '../../../connections/store/connections.actions';
import { getDataForSchemaImporter } from '../../helpers/schema.helpers';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { hasTableSchemaEndpointsHelper } from '../../../connections/helpers/connection-types.helpers';
import { VariablesEvaluationService } from '../../helpers/variables-evaluation.service';

@Component({
  selector: 'database-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
          <dynamic-connection [component]="rawComponent" *ngxPermissionsOnly="'dynamicConnection'"></dynamic-connection>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="database-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::DatabaseSourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="row">
                  <div class="col-md-12">
                    <database-definition
                      [hasAccessMode]="true"
                      [hasWhereClause]="true"
                      [hasSchema]="true"
                      [hasQuery]="true"
                      [component]="component"
                      [rawComponent]="rawComponent"
                      [hasTableSchemaSelect]="hasTableSchemaSelect"
                    >
                    </database-definition>
                    <div class="form-group" *ngIf="rawComponent.access_mode === 'table'">
                      <small class="hint">{{
                        'database-source-editor.form.hints.split_by_column_name' | translate
                      }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div *ngIf="rawComponent.access_mode === 'table'">
                      <xp-form-group>
                        <label for="split_by_column_name">{{
                          'database-source-editor.form.labels.split_by_column' | translate
                        }}</label>
                        <xp-input
                          type="text"
                          name="split_by_column_name"
                          id="split_by_column_name"
                          class="form-control"
                          ng-model="component.split_by_column_name"
                          [ngModel]="rawComponent.split_by_column_name"
                          (ngModelChange)="onValueChange($event, 'split_by_column_name')"
                        ></xp-input>
                      </xp-form-group>
                      <xp-form-group class="parallel" [validationDisabled]="true">
                        <label for="parallel">{{ 'database-source-editor.form.labels.parallel' | translate }}</label>
                        <input
                          type="number"
                          class="form-control"
                          name="parallel"
                          id="parallel"
                          [ngModel]="rawComponent.parallel"
                          (ngModelChange)="onValueChange($event, 'parallel')"
                          min="1"
                          max="100"
                          required
                          [disabled]="!rawComponent.split_by_column_name || rawComponent.split_by_column_name === ''"
                          [placeholder]="'database-source-editor.form.placeholders.parallel' | translate"
                        />
                      </xp-form-group>
                    </div>
                    <div class="source-action-buttons">
                      <label for="before_action">{{
                        'database-source-editor.form.labels.before_action' | translate
                      }}</label>
                      <div class="btn-group btn-group-md btn-group-select">
                        <button
                          type="button"
                          class="btn btn-default"
                          value="none"
                          [ngClass]="{ 'active btn-primary': rawComponent.before_action === 'none' }"
                          (click)="onValueChange('none', 'before_action')"
                        >
                          {{ 'database-source-editor.form.before_action.options.none' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          value="copy"
                          [ngClass]="{ 'active btn-primary': rawComponent.before_action === 'copy' }"
                          (click)="onValueChange('copy', 'before_action')"
                        >
                          {{ 'database-source-editor.form.before_action.options.copy' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" (activate)="activateSchemaStep()" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            [errorMessage]="schemaImporterErrorMessage"
            (updateValidation)="updateSchemaValidation($event)"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class DatabaseSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges, OnDestroy {
  @Input() rawComponent: DatabaseSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;
  schemaImporterErrorMessage = '';

  isSchemaValid = true;
  isFormValid = true;
  isBaseComponentStepActivated = false;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});
  hasTableSchemaSelect = true;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private authGuard: AuthorizationGuard,
    private changeDetectorRef: ChangeDetectorRef,
    private variablesEvaluationService: VariablesEvaluationService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(
          {
            ...this.rawComponent,
            schema_name: !this.rawComponent.schema_name ? 'Default' : this.rawComponent.schema_name,
          },
          this.component,
        ),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(
            {
              ...this.rawComponent,
              schema_name: !this.rawComponent.schema_name ? 'Default' : this.rawComponent.schema_name,
            },
            this.component,
          )
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    if (this.rawComponent.connection?.id) {
      this.hasTableSchemaSelect = hasTableSchemaEndpointsHelper(this.rawComponent.connection.type);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(
            {
              ...this.rawComponent,
              schema_name: !this.rawComponent.schema_name ? 'Default' : this.rawComponent.schema_name,
            },
            this.component,
          )
        : [],
    };

    if (
      changes.rawComponent?.currentValue?.dynamic_connection !== changes.rawComponent?.previousValue?.dynamic_connection
    ) {
      if (this.rawComponent.dynamic_connection) {
        if (!this.connectionStep.tags.find((tag) => tag.name === this.component.dynamic_connection)) {
          this.connectionStep.tags = [
            ...this.connectionStep.tags,
            {
              name: this.component.dynamic_connection,
            },
          ];
        }
      } else {
        this.connectionStep.tags = this.connectionStep.tags.filter(
          (tag) => tag.name !== changes.component.previousValue.dynamic_connection,
        );
      }
      this.connectionStep = { ...this.connectionStep };
    }
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    this.isSchemaValid = isSchemaValid;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  activateSchemaStep() {
    if (this.rawComponent.connection?.id) {
      this.store.dispatch(setConnectionSchemaLoadingFlag({ value: true }));
      const schemaRequest = getDataForSchemaImporter(
        this.rawComponent,
        this.component.componentType,
        this.authGuard.account.account_id,
      );

      this.variablesEvaluationService.interpolateVariablesInData(schemaRequest).subscribe({
        next: (schemaRequestData) => {
          this.store.dispatch(
            getConnectionSchema({
              connectionId: this.rawComponent.connection?.id,
              connectionType: this.rawComponent.connection?.type,
              schemaRequestData,
            }),
          );
        },
        error: (error) => {
          this.store.dispatch(setConnectionSchemaLoadingFlag({ value: false }));
          this.schemaImporterErrorMessage = error;
        },
      });
    }
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<AnyConnection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    if (this.rawComponent.dynamic_connection) {
      this.connectionStep.tags = [...this.connectionStep.tags, { name: this.rawComponent.dynamic_connection }];
    }

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };

    this.hasTableSchemaSelect = hasTableSchemaEndpointsHelper(connection.type);
    this.changeDetectorRef.markForCheck();
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { schema: { ...this.rawComponent.schema, fields } },
      }),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
