import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Job } from '../../jobs.models';
import { JobsResource } from '../../resources/jobs.resource';
import { NotifyService } from '../../../common/services/notify.service';
import {
  selectAIErrorSummaryFeatureFlag,
  selectAIJobExplanationFeatureFlag,
} from '../../../account/store/account.selectors';
import { AppState } from '../../../store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'job-errors',
  template: `
    <div class="job-errors job-content">
      <div
        class="explain-error-container"
        *ngIf="!(isAIErrorSummaryEnabled$ | async) && (isAIJobExplanationEnabled$ | async) && isJobLogRichError"
      >
        <div class="explain-error" *ngIf="!isExplanationLoading">
          {{ explanation }}
        </div>
        <xp-loader *ngIf="isExplanationLoading"></xp-loader>
        <button type="button" class="btn btn-default btn-primary" (click)="explainError()" *ngIf="!hideButton">
          Summarize Error Log
        </button>
      </div>
      <div class="explain-error-container" *ngIf="isAIErrorSummaryEnabled$ | async">
        <div class="explain-error summary-container" *ngIf="errorSummary">
          <div class="explain-error summary">
            <div
              class="explain-error-title"
              matTooltipPosition="left"
              matTooltipClass="left wide-400"
              matTooltip="This is a new feature still in Beta. The AI model powering it is still getting fine-tuned so please help us improve it by giving your feedback (thumbs up/down on right)."
            >
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>Error Summary:
            </div>
            {{ errorSummary }}
          </div>
        </div>
        <button
          type="button"
          class="btn btn-default btn-primary"
          *ngIf="!hideToggleButton"
          (click)="toggleShowOriginalStackTrace()"
        >
          {{ isStackTraceVisible ? 'Hide stack trace' : 'Show stack trace' }}
        </button>
      </div>
      <div class="explain-error-container" *ngIf="isAIErrorSummaryEnabled$ | async">
        <div class="explain-error action-items" *ngIf="actionItems && actionItems.length">
          <div
            class="explain-error-title"
            matTooltipPosition="left"
            matTooltipClass="left wide-400"
            matTooltip="This is a new feature still in Beta. The AI model powering it is still getting fine-tuned so please help us improve it by giving your feedback (thumbs up/down on right)."
          >
            <i class="fa fa-info-circle" aria-hidden="true"></i> Suggested Action Items:
          </div>
          <div class="action-item" *ngFor="let actionItem of actionItems">
            {{ actionItem }}
          </div>
        </div>
        <div class="feedback-container" *ngIf="errorSummary && !isSendingReview">
          <div class="feedback-text" *ngIf="!isFeedbackSent && !isFeedbackClicked">
            Was this helpful? Help us improve this feature
          </div>
          <div class="feedback-buttons" *ngIf="!isFeedbackSent && !isFeedbackClicked">
            <i class="fa fa-thumbs-up" (click)="feedbackClick(true)"></i>
            <i class="fa fa-thumbs-down" (click)="feedbackClick(false)"></i>
          </div>
          <div class="feedback-text" *ngIf="isFeedbackSent">
            Feedback has already been submitted for this job. Thanks for your input!
          </div>
          <div class="feedback-text" *ngIf="isFeedbackClicked">
            Thanks for your feedback! This will help us improve our improved error logs feature.
          </div>
        </div>
        <div class="feedback-container" *ngIf="errorSummary && isSendingReview">
          <xp-loader></xp-loader>
        </div>
      </div>

      <div class="explain-error-container" *ngIf="isAIErrorSummaryEnabled$ | async">
        <div class="feedback-container help-container" *ngIf="errorSummary">
          <div class="feedback-help-title"><i class="fa fa-info-circle" aria-hidden="true"></i>Still need help?</div>
          <div class="feedback-help-text">
            Click <span (click)="openIntercom()" class="feedback-help-text-link">here</span> to speak with our Support
            team
          </div>
        </div>
      </div>
      <pre *ngIf="isStackTraceVisible && jobLogBody" [innerHTML]="jobLogBody"></pre>
    </div>
  `,
})
export class JobErrorsComponent implements OnInit, OnChanges {
  @Input() item: Job;
  jobLogBody: string;
  isJobLogRichError = false;
  errorSummary = '';
  actionItems: string[] = [];
  isStackTraceVisible = false;
  explanation = '';
  isExplanationLoading = false;
  hideButton = false;
  hideToggleButton = true;
  isFeedbackSent = false;
  isFeedbackClicked = false;
  isSendingReview = false;
  isAIJobExplanationEnabled$ = this.store.select(selectAIJobExplanationFeatureFlag);
  isAIErrorSummaryEnabled$ = this.store.select(selectAIErrorSummaryFeatureFlag).pipe(
    tap((isFlagEnabled) => {
      this.isStackTraceVisible = !isFlagEnabled;
    }),
  );

  constructor(
    private jobsResource: JobsResource,
    private notify: NotifyService,
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.jobsResource.log(this.item.id).subscribe({
      next: (jobLog) => {
        this.jobLogBody = jobLog.openai_extract
          ? jobLog.body.replace(jobLog.openai_extract, `<span class="highlight-error">${jobLog.openai_extract}</span>`)
          : jobLog.body;
        this.explanation = jobLog.ai_explanation;
        if (this.explanation) {
          this.hideButton = true;
        }

        if ((!jobLog.action_items || jobLog.action_items.length === 0) && !jobLog.error_summary) {
          this.isStackTraceVisible = true;
          this.hideToggleButton = true;
        } else {
          this.hideToggleButton = false;
        }

        this.isFeedbackSent = !!jobLog.review_status;
        this.errorSummary = jobLog.error_summary;
        this.actionItems = (jobLog.action_items || []).map((item) =>
          item.includes('Solution') ? item.replace(/Solution [0-9]:/, '- ') : `- ${item}`,
        );
        this.isJobLogRichError = this.jobLogBody !== '';

        if (this.jobLogBody === '') {
          this.jobLogBody = this.item.errors;
        }
      },
      error: (error) => {
        this.notify.error(this.translate.instant(`response.${error.status}.message`));
      },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && changes.item.currentValue) {
      if (this.item.errors && this.jobLogBody === '') {
        this.jobLogBody = this.item.errors;
      }
    }
  }

  toggleShowOriginalStackTrace() {
    this.isStackTraceVisible = !this.isStackTraceVisible;
  }

  explainError() {
    this.isExplanationLoading = true;
    this.jobsResource.explain(this.item.id).subscribe({
      next: (res) => {
        this.explanation = res.message;
        this.isExplanationLoading = false;
        this.hideButton = true;
      },
      error: (error) => {
        this.notify.error(this.translate.instant(`response.${error.status}.message`));
        this.isExplanationLoading = false;
      },
    });
  }

  feedbackClick(isPositive: boolean) {
    this.isSendingReview = true;
    this.jobsResource.vote(this.item.id, isPositive ? 'liked' : 'disliked').subscribe({
      next: () => {
        this.isFeedbackClicked = true;
        this.isSendingReview = false;
      },
      error: () => {
        this.isSendingReview = false;
        this.notify.error('There was an issue with review response. Please try again.');
      },
    });
  }

  openIntercom() {
    if (window['Intercom']) {
      window['Intercom']('show');
    }
  }
}
