export enum ConnectionTypeName {
  adwords = 'adwords',
  analytics = 'analytics',
  analyticsV4 = 'ga4',
  azureSynapseAnalytics = 'azuresynapseanalytics',
  bigQuery = 'bigquery',
  bigQueryV2 = 'bigqueryv2',
  bingAds = 'bingads',
  db2 = 'db2',
  as400 = 'as400',
  googleCloudSql = 'googlecloudsql',
  gs = 'gs',
  gsv2 = 'gsv2',
  hubspot = 'hubspot',
  hdfs = 'hdfs',
  herokuPostgres = 'herokupostgres',
  mongo = 'mongo',
  mysql = 'mysql',
  netsuite = 'netsuite',
  netsuitesoap = 'netsuitesoap',
  oracle = 'oracle',
  oracleADW = 'oracleadw',
  postgres = 'postgres',
  rackspace = 'rackspace',
  redshift = 'redshift',
  s3 = 's3',
  salesforce = 'salesforce',
  sftp = 'sftp',
  ftps = 'ftps',
  softlayer = 'softlayer',
  sqlserver = 'sqlserver',
  swift = 'swift',
  googleCloudSpanner = 'googlecloudspanner',
  googleCloudPostgres = 'googlecloudpostgres',
  alloy = 'alloy',
  facebookAdsInsights = 'facebookadsinsights',
  intercom = 'intercom',
  azureBlobStorage = 'azureblobstorage',
  pipedrive = 'pipedrive',
  snowflake = 'snowflake',
  xero = 'xero',
  linkedin = 'linkedin',
  instagram = 'instagram',
  googleSheets = 'googlesheets',
  googleDrive = 'googledrive',
  youtube = 'youtube',
  shopify = 'shopify',
  athena = 'athena',
  vertica = 'vertica',
  restapi = 'restapi',
  marketingCloud = 'marketingcloud',
  marketingCloudRest = 'marketingcloudrest',
  facebookAds = 'facebookads',
  salesforceSoap = 'salesforcesoap',
  tiktokads = 'tiktokads',
  hubspotOAuth = 'hubspotoauth',
}

export interface TimeZoneInfo {
  identifier: string;
  name: string;
  offset: string;
}

export interface Owner {
  avatar_url: string;
  confirmed_at: string;
  created_at: string;
  display_name: string;
  email: string;
  id: number;
  location?: string;
  name: string;
  time_zone: string;
  time_zone_info: TimeZoneInfo;
  updated_at: string;
}

export enum AuthMethod {
  password = 'password',
  key = 'key',
  shopify = 'shopify',
  pinterest = 'pinterest',
  swauth = 'swauth',
  salesforce = 'salesforce',
  salesforceSandbox = 'salesforcesandbox',
}

export enum MongoConnectionScheme {
  replicaMembers = 'replica-members',
  dnsSeedList = 'dns-seed-list',
}

export enum MongoReadPreference {
  primary = 'primary',
  primaryPreferred = 'primaryPreferred',
  secondary = 'secondary',
  secondaryPreferred = 'secondaryPreferred',
  nearest = 'nearest',
}

export enum SalesforceEnvironment {
  production = 'production',
  sandbox = 'sandbox',
}

interface DefaultConnection {
  id: number;
  name: string;
  unique_id: string;
  created_at: string;
  updated_at: string;
  owner_id: number;
  type: ConnectionTypeName;
  url: string;
  uid: string;
  expires: true;
  expires_at: number;
  username: string;
  password?: string;
  passwordDisabled?: boolean;
  tunnel_type: string;
  local_port: number;
  auth_method?: AuthMethod;
  timestamp?: string;
  hmac?: string;
  code?: string;
  redirect_uri?: string;
  jwt_token?: string;
  owner?: Owner;
  isRemoved?: boolean;
  service_name?: string;
}

export interface DatabaseDefaultConnection extends DefaultConnection {
  ssl: boolean;
  is_using_certificate?: boolean;
  ssl_ca_certificate?: string;
  ssl_certificate?: string;
  ssl_private_key?: string;
  database: string;
  tunnel_type: string;
  port: number;
  host: string;
  saved?: boolean;
  public_key?: string;
}

export interface DatabaseDefaultConnectionWithSSL extends DatabaseDefaultConnection {
  ssh_host?: string;
  ssh_username?: string;
  ssh_port?: number;
}

export interface AdWordsConnection extends DefaultConnection {}
export interface AnalyticsConnection extends DefaultConnection {}
export interface AthenaConnection extends DefaultConnection {
  region: string;
  s3_staging_dir: string;
  database: string;
  tunnel_type: string;
  port: number;
  host: string;
}

export interface AzureSynapseAnalyticsConnection extends DatabaseDefaultConnectionWithSSL {}
export interface AzureBlobStorageConnection extends DefaultConnection {
  ssl: boolean;
}
export interface BigQueryConnection extends DefaultConnection {
  region: string;
  database: string;
  host: string;
}
export interface BigQueryConnectionV2 extends DefaultConnection {
  region: string;
  database: string;
  host: string;
  password: string;
  client_email: string;
  json_key: {
    type: string;
    project_id: string;
    private_key_id: string;
    private_key?: string;
    client_email: string;
    client_id: string;
    auth_uri: string;
    token_uri: string;
    auth_provider_x509_cert_url: string;
    client_x509_cert_url: string;
  };
}
export interface GoogleAnalyticsConnectionV4 extends DefaultConnection {
  username: string;
  host: string;
  password: string;
  json_key: {
    type: string;
    project_id: string;
    private_key_id: string;
    client_email: string;
    client_id: string;
    auth_uri: string;
    token_uri: string;
    auth_provider_x509_cert_url: string;
    client_x509_cert_url: string;
    private_key?: string;
  };
}
export interface BindAdsConnection extends DefaultConnection {}
export interface DB2Connection extends DatabaseDefaultConnectionWithSSL {}
export interface As400Connection extends DatabaseDefaultConnectionWithSSL {}
export interface FacebookAdsInsightsConnection extends DefaultConnection {}
export interface FacebookAdsConnection extends DefaultConnection {}
export interface FTPSConnection extends DefaultConnection {
  host: string;
}
export interface GoogleCloudPostgresConnection extends DatabaseDefaultConnectionWithSSL {}
export interface GoogleCloudSpannerConnection extends DefaultConnection {
  instance_id: string;
  database: string;
  data_boost: boolean;
}
export interface GoogleCloudSqlConnection extends DatabaseDefaultConnectionWithSSL {}
export interface GoogleCloudSheetsConnection extends DefaultConnection {}
export interface GoogleCloudDriveConnection extends DefaultConnection {}
export interface GoogleCloudStorageConnection extends DefaultConnection {
  project_id: string;
}
export interface GoogleCloudStorageV2Connection extends DefaultConnection {
  project_id: string;
  client_email: string;
  json_key: {
    type: string;
    project_id: string;
    private_key_id: string;
    client_email: string;
    client_id: string;
    auth_uri: string;
    token_uri: string;
    auth_provider_x509_cert_url: string;
    client_x509_cert_url: string;
    private_key: string;
  };
}
export interface HDFSConnection extends DefaultConnection {
  name_node_host: string;
  name_node_port: number;
  httpfs_host: string;
  httpfs_port: number;
}
export interface HerokuPostgresConnection extends DatabaseDefaultConnectionWithSSL {}
export interface InstagramConnection extends DefaultConnection {}
export interface IntercomConnection extends DefaultConnection {}
export interface LinkedinConnection extends DefaultConnection {}
export interface MarketingCloudConnection extends DefaultConnection {
  client_id: string;
  client_secret: string;
  subdomain: string;
  account_id: string;
}
export interface MarketingCloudRestConnection extends DefaultConnection {
  client_id: string;
  client_secret: string;
  subdomain: string;
  account_id: string;
}
export interface MongoConnection extends DatabaseDefaultConnection {
  replica_set_members: string[];
  connection_scheme: MongoConnectionScheme;
  read_preference: MongoReadPreference;
  authentication_database: string;
}
export interface MySQLConnection extends DatabaseDefaultConnectionWithSSL {}
export interface HubspotConnection extends DefaultConnection {
  name: string;
  password: string;
}
export interface NetsuiteConnection extends DefaultConnection {
  port: number;
  host: string;
  database: string;
  net_suite_account_id: string;
  role_id: number;
}
export interface NetsuiteDestinationConnection extends DefaultConnection {
  net_suite_account_id: string;
  consumer_key: string;
  consumer_secret: string;
  token_id: string;
  token_secret: string;
}
export interface OracleConnection extends DatabaseDefaultConnectionWithSSL {}
export interface OracleADWConnection extends DatabaseDefaultConnectionWithSSL {
  service_name: string;
}
export interface PipedriveConnection extends DefaultConnection {}
export interface PostgresConnection extends DatabaseDefaultConnectionWithSSL {}
export interface RackspaceConnection extends DefaultConnection {
  region: string;
}
export interface RedshiftConnection extends DatabaseDefaultConnectionWithSSL {
  region: string;
}
export interface S3Connection extends DefaultConnection {
  region: string;
}
export interface SalesforceConnection extends DefaultConnection {
  environment: SalesforceEnvironment;
}
export interface SalesforceSoapConnection extends DefaultConnection {
  username: string;
  password: string;
  security_token: string;
  instance_url: string;
}
export interface SftpConnection extends DatabaseDefaultConnectionWithSSL {}
export interface ShopifyConnection extends DefaultConnection {
  shop: string;
}
export interface SnowflakeConnection extends DefaultConnection {
  region: string;
  warehouse: string;
  database: string;
  account_name: string;
}
export interface SoftlayerConnection extends DefaultConnection {
  region: string;
}
export interface SQLServerConnection extends DatabaseDefaultConnectionWithSSL {}
export interface SwiftConnection extends DefaultConnection {
  tenant_name: string;
  authentication_service: string;
}
export interface VerticaConnection extends DatabaseDefaultConnectionWithSSL {}
export interface XeroConnection extends DefaultConnection {}
export interface YoutubeConnection extends DefaultConnection {}
export interface TiktokAdsConnection extends DefaultConnection {}

export type AnyConnection = AdWordsConnection &
  AnalyticsConnection &
  AthenaConnection &
  AzureSynapseAnalyticsConnection &
  AzureBlobStorageConnection &
  BigQueryConnection &
  BindAdsConnection &
  DB2Connection &
  FacebookAdsConnection &
  FacebookAdsInsightsConnection &
  FTPSConnection &
  GoogleCloudPostgresConnection &
  GoogleCloudSpannerConnection &
  GoogleCloudSqlConnection &
  GoogleCloudSheetsConnection &
  GoogleCloudDriveConnection &
  GoogleCloudStorageConnection &
  GoogleCloudStorageV2Connection &
  HDFSConnection &
  HerokuPostgresConnection &
  InstagramConnection &
  IntercomConnection &
  LinkedinConnection &
  MarketingCloudConnection &
  MarketingCloudRestConnection &
  MongoConnection &
  MySQLConnection &
  NetsuiteConnection &
  NetsuiteDestinationConnection &
  OracleConnection &
  OracleADWConnection &
  PipedriveConnection &
  PostgresConnection &
  RackspaceConnection &
  RedshiftConnection &
  S3Connection &
  SalesforceConnection &
  SalesforceSoapConnection &
  SftpConnection &
  ShopifyConnection &
  SnowflakeConnection &
  SoftlayerConnection &
  SQLServerConnection &
  SwiftConnection &
  VerticaConnection &
  XeroConnection &
  YoutubeConnection;

export type SomeConnection =
  | AdWordsConnection
  | AnalyticsConnection
  | AthenaConnection
  | AzureSynapseAnalyticsConnection
  | AzureBlobStorageConnection
  | BigQueryConnection
  | BindAdsConnection
  | DB2Connection
  | FacebookAdsInsightsConnection
  | FacebookAdsConnection
  | FTPSConnection
  | GoogleCloudPostgresConnection
  | GoogleCloudSpannerConnection
  | GoogleCloudSqlConnection
  | GoogleCloudSheetsConnection
  | GoogleCloudDriveConnection
  | GoogleCloudStorageConnection
  | GoogleCloudStorageV2Connection
  | HDFSConnection
  | HerokuPostgresConnection
  | InstagramConnection
  | IntercomConnection
  | LinkedinConnection
  | MarketingCloudConnection
  | MarketingCloudRestConnection
  | MongoConnection
  | MySQLConnection
  | NetsuiteConnection
  | NetsuiteDestinationConnection
  | OracleConnection
  | OracleADWConnection
  | PipedriveConnection
  | PostgresConnection
  | RackspaceConnection
  | RedshiftConnection
  | S3Connection
  | SalesforceConnection
  | SalesforceSoapConnection
  | SftpConnection
  | ShopifyConnection
  | SnowflakeConnection
  | SoftlayerConnection
  | SQLServerConnection
  | SwiftConnection
  | VerticaConnection
  | XeroConnection
  | YoutubeConnection;

interface Group {
  group_type: string;
  group_name: string;
}

export interface ConnectionType {
  description: string;
  groups: Group[];
  icon_url: string;
  name: string;
  type: ConnectionTypeName;
}

export interface ConnectionAccount {
  account_id: string;
  id: string;
  name: string;
  campaigns?: any[];
  customer_id?: string;
  can_manage_clients?: boolean;
  parent_customer_id?: string;
  type?: string;
}

export interface GA4Properties {
  id: string;
  name: string;
}

export interface GA4Account {
  id: string;
  name: string;
  properties: GA4Properties;
}

export interface GA4PropertiesResponse {
  data: GA4Account[];
  nextPageToken: string;
}

export const CLOUD_STORAGE_CONNECTION_TYPES = [
  ConnectionTypeName.adwords,
  ConnectionTypeName.analytics,
  ConnectionTypeName.analyticsV4,
  ConnectionTypeName.bingAds,
  ConnectionTypeName.gs,
  ConnectionTypeName.gsv2,
  ConnectionTypeName.hdfs,
  ConnectionTypeName.rackspace,
  ConnectionTypeName.hubspot,
  ConnectionTypeName.hubspotOAuth,
  ConnectionTypeName.s3,
  ConnectionTypeName.salesforce,
  ConnectionTypeName.salesforceSoap,
  ConnectionTypeName.sftp,
  ConnectionTypeName.ftps,
  ConnectionTypeName.softlayer,
  ConnectionTypeName.swift,
  ConnectionTypeName.facebookAdsInsights,
  ConnectionTypeName.intercom,
  ConnectionTypeName.azureBlobStorage,
  ConnectionTypeName.pipedrive,
  ConnectionTypeName.xero,
  ConnectionTypeName.linkedin,
  ConnectionTypeName.instagram,
  ConnectionTypeName.googleSheets,
  ConnectionTypeName.googleDrive,
  ConnectionTypeName.youtube,
  ConnectionTypeName.shopify,
  ConnectionTypeName.restapi,
  ConnectionTypeName.marketingCloud,
  ConnectionTypeName.marketingCloudRest,
  ConnectionTypeName.netsuitesoap,
  ConnectionTypeName.facebookAds,
  ConnectionTypeName.tiktokads,
];

export const DATABASE_CONNECTION_TYPES = [
  ConnectionTypeName.postgres,
  ConnectionTypeName.redshift,
  ConnectionTypeName.mysql,
  ConnectionTypeName.herokuPostgres,
  ConnectionTypeName.oracle,
  ConnectionTypeName.oracleADW,
  ConnectionTypeName.sqlserver,
  ConnectionTypeName.mongo,
  ConnectionTypeName.bigQuery,
  ConnectionTypeName.googleCloudSql,
  ConnectionTypeName.db2,
  ConnectionTypeName.as400,
  ConnectionTypeName.netsuite,
  ConnectionTypeName.netsuitesoap,
  ConnectionTypeName.googleCloudSpanner,
  ConnectionTypeName.googleCloudPostgres,
  ConnectionTypeName.alloy,
  ConnectionTypeName.snowflake,
  ConnectionTypeName.athena,
  ConnectionTypeName.azureSynapseAnalytics,
  ConnectionTypeName.vertica,
];

export const CloudStorageConnectionsQuery = `${ConnectionTypeName.s3},${ConnectionTypeName.gs},${ConnectionTypeName.gsv2},${ConnectionTypeName.sftp},${ConnectionTypeName.ftps},${ConnectionTypeName.azureBlobStorage}`;

export const ConnectionsWithBucket = [ConnectionTypeName.s3, ConnectionTypeName.gs, ConnectionTypeName.gsv2];
