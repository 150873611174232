import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { COMPONENT_TYPE } from '../../../../constants/component_type';
import { RestApiDestinationComponentData, Schema } from '../../../package.models';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { REST_API_REQUEST_TYPES } from '../../../../constants/component_types';
import { updateComponent, updateRawComponent } from '../../../store/component.actions';

function autoGenerateAlias(record: DesignerSchemaFieldI): string {
  if (record && record.field_name) {
    return record.field_name;
  }
  return '';
}

interface TablePreview {
  fields: string[];
  items: {
    values: string[];
  }[];
}

const JSON_PLACEHOLDER = '{\n  "type": "transaction"\n  "start_at": "2019-10-01"\n}\n';
const CSV_PLACEHOLDER = 'field1,field2,field3\nvalue1,value2,value3';

@Component({
  selector: 'schema-mapping-rest-api-collection',
  template: `
    <div
      *ngIf="
        isCustomPayload && (requestType === REST_API_REQUEST_TYPES.json || requestType === REST_API_REQUEST_TYPES.csv)
      "
      class="schema-mapping-database"
    >
      <h4>Request body</h4>
      <hr />
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <code-editor
              [value]="rawComponent.custom_payload"
              [options]="payloadDataOptions"
              editorTitle="JSON Editor"
              name="Custom Payload"
              (valueChange)="onValueChange($event, 'custom_payload')"
            ></code-editor>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        isCustomPayload && requestType !== REST_API_REQUEST_TYPES.json && requestType !== REST_API_REQUEST_TYPES.csv
      "
    >
      <h4>Custom attributes mapping</h4>
      <hr />
      <xp-fields-collection
        [records]="customPayloadRecordsCopy"
        [collectionSettings]="customPayloadCollectionSettings"
        [isValid]="customPayloadValid"
        (validityChange)="onCustomPayloadFieldsValidityChange($event)"
        (recordsChange)="onCustomPayloadRecordChange($event)"
        [columns]="customPayloadColumns"
      >
        <ng-template templateName="field_name" let-item>
          <xp-database-column-editor
            [hideEditor]="true"
            [value]="item.record.column_name"
            [index]="item.index"
            [focusedProp]="item.focusedProp"
            [isDuplicateError]="item.record.isDuplicateError"
            propName="column_name"
            (fieldChange)="onCustomPayloadFieldChange($event, item.record, 'column_name')"
            class="fields-collection-editor"
          ></xp-database-column-editor>
        </ng-template>
        <ng-template templateName="field_name-header" let-item>
          <span>Attribute Name</span>
        </ng-template>

        <ng-template templateName="column_name" let-item>
          <xp-database-column-editor
            [hideEditor]="true"
            [value]="item.record.field_name"
            [index]="item.index"
            propName="field_name"
            (fieldChange)="onCustomPayloadFieldChange($event, item.record, 'field_name')"
            class="fields-collection-editor"
          ></xp-database-column-editor>
        </ng-template>
        <ng-template templateName="column_name-header" let-item>
          <span>Attribute Value</span>
        </ng-template>
      </xp-fields-collection>
      <pre>{{ rawComponent.custom_payload }}</pre>
    </div>

    <div *ngIf="!isCustomPayload" class="schema-mapping-database">
      <h4>Records attributes mapping</h4>
      <hr />
      <xp-fields-collection
        [records]="recordsCopy"
        [collectionSettings]="collectionSettings"
        [isValid]="valid"
        (validityChange)="onFieldsValidityChange($event)"
        (recordsChange)="onRecordChange($event)"
        [hideAutofill]="rawComponent.set_field_name"
        [hideAddAndRemove]="rawComponent.set_field_name"
        [columns]="columns"
        duplicationValidationProp="column_name"
        duplicationValidationPropName="Destination column name"
      >
        <ng-template templateName="field_name" let-item>
          <xp-field-picker
            [value]="item.record.field_name"
            [index]="item.index"
            [schema]="parentSchemas[0]"
            [fields]="(parentSchemas[0] || {}).fields || []"
            propName="field_name"
            (fieldChange)="onFieldChange($event, item.record, 'field_name')"
            class="fields-collection-editor"
          ></xp-field-picker>
        </ng-template>
        <ng-template templateName="field_name-header" let-item>
          <span>{{ 'schema-mapping-database.headers.input-fields' | translate }}</span>
        </ng-template>

        <ng-template templateName="column_name" let-item>
          <xp-database-column-editor
            [value]="item.record.column_name"
            [index]="item.index"
            [focusedProp]="item.focusedProp"
            [isDuplicateError]="item.record.isDuplicateError"
            [disabled]="rawComponent.set_field_name"
            [hideEditor]="rawComponent.set_field_name"
            [hideValue]="rawComponent.set_field_name"
            propName="column_name"
            (fieldChange)="onFieldChange($event, item.record, 'column_name')"
            class="fields-collection-editor"
          ></xp-database-column-editor>
        </ng-template>
        <ng-template templateName="column_name-header" let-item>
          <span>{{ 'schema-mapping-database.headers.destination-column' | translate }}</span>
        </ng-template>

        <ng-template templateName="field_type" let-item>
          <xp-type-picker
            [value]="item.record.field_type"
            [index]="item.index"
            (valueChange)="onFieldChange($event, item.record, 'field_type')"
            class="fields-collection-editor"
          ></xp-type-picker>
        </ng-template>

        <ng-template templateName="field_type-header" let-item>
          <span>Value Type</span>
        </ng-template>
      </xp-fields-collection>

      <div class="row" *ngIf="requestType === REST_API_REQUEST_TYPES.json && isBatch">
        <div class="col-md-12">
          <xp-input-checkbox
            name="set_field_name"
            [ngModel]="rawComponent.set_field_name"
            (ngModelChange)="onValueChange($event, 'set_field_name')"
            [labelText]="'rest-api-destination-editor.form.labels.set_field_name' | translate"
          ></xp-input-checkbox>
        </div>
      </div>

      <div class="row" *ngIf="requestType === REST_API_REQUEST_TYPES.json">
        <div class="col-md-12">
          <xp-input-checkbox
            name="send_records_as_nested_attribute"
            [ngModel]="rawComponent.send_records_as_nested_attribute"
            (ngModelChange)="onValueChange($event, 'send_records_as_nested_attribute')"
            [labelText]="
              (isBatch
                ? 'rest-api-destination-editor.form.labels.send_records_as_nested_attribute_batch'
                : 'rest-api-destination-editor.form.labels.send_records_as_nested_attribute'
              ) | translate
            "
          ></xp-input-checkbox>
        </div>
      </div>

      <div
        class="row"
        *ngIf="requestType === REST_API_REQUEST_TYPES.json && rawComponent.send_records_as_nested_attribute"
        style="margin-bottom: 10px;"
      >
        <div class="col-md-12">
          <label for="nested_attribute_name">{{
            'rest-api-destination-editor.form.labels.nested_attribute_name' | translate
          }}</label>
          <xp-input
            type="text"
            class="form-control"
            name="nested_attribute_name"
            id="nested_attribute_name"
            [ngModel]="rawComponent.nested_attribute_name"
            (ngModelChange)="onValueChange($event, 'nested_attribute_name')"
            [placeholder]="isBatch ? 'records' : 'record'"
          ></xp-input>
        </div>
      </div>

      <div *ngIf="requestType === REST_API_REQUEST_TYPES.json && rawComponent.send_records_as_nested_attribute">
        <h4 class="middle-header">Additional attributes mapping</h4>
        <hr />

        <xp-fields-collection
          *ngIf="requestType === REST_API_REQUEST_TYPES.json && rawComponent.send_records_as_nested_attribute"
          [records]="constRecordsCopy"
          [collectionSettings]="constsCollectionSettings"
          [isValid]="constsValid"
          (validityChange)="onConstFieldsValidityChange($event)"
          (recordsChange)="onConstRecordChange($event)"
          [columns]="columns"
        >
          <ng-template templateName="field_name" let-item>
            <xp-database-column-editor
              [hideEditor]="true"
              [value]="item.record.field_name"
              [index]="item.index"
              [focusedProp]="item.focusedProp"
              [isDuplicateError]="item.record.isDuplicateError"
              propName="field_name"
              (fieldChange)="onConstFieldChange($event, item.record, 'field_name')"
              class="fields-collection-editor"
            ></xp-database-column-editor>
          </ng-template>
          <ng-template templateName="field_name-header" let-item>
            <span>Attribute Name</span>
          </ng-template>

          <ng-template templateName="column_name" let-item>
            <xp-database-column-editor
              [hideEditor]="true"
              [value]="item.record.column_name"
              [index]="item.index"
              propName="column_name"
              (fieldChange)="onConstFieldChange($event, item.record, 'column_name')"
              class="fields-collection-editor"
            ></xp-database-column-editor>
          </ng-template>
          <ng-template templateName="column_name-header" let-item>
            <span>Attribute Value</span>
          </ng-template>

          <ng-template templateName="field_type" let-item>
            <xp-type-picker
              [value]="item.record.field_type"
              [index]="item.index"
              (valueChange)="onConstFieldChange($event, item.record, 'field_type')"
              class="fields-collection-editor"
            ></xp-type-picker>
          </ng-template>

          <ng-template templateName="field_type-header" let-item>
            <span>Value Type</span>
          </ng-template>
        </xp-fields-collection>
      </div>
      <div class="data-preview">
        <h4 class="middle-header">Request preview</h4>
        <hr />
        <p class="data-preview-description">
          This is a preview of how the form data will be serialized and sent in an HTTP request.
        </p>
        <pre *ngIf="requestType !== REST_API_REQUEST_TYPES.csv">{{ formattedPreview }}</pre>
        <div *ngIf="requestType === REST_API_REQUEST_TYPES.csv" class="data-preview-table">
          <div class="data-preview-table-wrapper">
            <table class="table" *ngIf="tablePreview.fields.length > 0">
              <thead>
                <tr>
                  <th *ngFor="let field of tablePreview.fields">{{ field }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of tablePreview.items; let index = index">
                  <td *ngFor="let value of item.values">{{ value | showNullValues }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  `,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchemaMappingRestApiCollectionComponent implements OnInit, OnChanges {
  @Input() rawComponent: RestApiDestinationComponentData;
  @Input() records: DesignerSchemaFieldI[] = [];
  @Input() valid: boolean;
  @Input() parentSchemas: Schema[];
  @Input() operationType: string;
  @Input() componentType: string;
  @Input() isBatch: boolean;
  @Input() requestType: REST_API_REQUEST_TYPES;
  @Input() isCustomPayload: boolean;
  @Output() recordsChange = new EventEmitter();
  @Output() constsRecordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  collectionSettings: CollectionSettings;
  constsCollectionSettings: CollectionSettings;
  customPayloadCollectionSettings: CollectionSettings;
  columns: string[];
  errorMessage: string;
  constsValid = true;
  customPayloadValid = true;
  customPayloadColumns = ['field_name', 'column_name'];

  payloadDataOptions = {
    lineWrapping: true,
    lineNumbers: true,
    mode: 'application/json',
    placeholder: JSON_PLACEHOLDER,
  };

  recordsCopy: DesignerSchemaFieldI[] = [];
  constRecordsCopy: DesignerSchemaFieldI[] = [];
  customPayloadRecordsCopy: DesignerSchemaFieldI[] = [];
  jsonPreview: any = {};
  tablePreview: TablePreview = {
    fields: [],
    items: [],
  };
  formattedPreview = '';
  REST_API_REQUEST_TYPES = REST_API_REQUEST_TYPES;

  constructor(private store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.operationType) {
      this.validityChange.emit(this.validateSchemaMapping());
    }

    if (changes.requestType?.currentValue !== changes.requestType?.previousValue && !changes.requestType?.firstChange) {
      let columns = ['field_name', 'column_name'];

      if (this.requestType === REST_API_REQUEST_TYPES.json) {
        columns = ['field_name', 'column_name', 'field_type'];
        this.payloadDataOptions.placeholder = JSON_PLACEHOLDER;
      }

      if (this.requestType === REST_API_REQUEST_TYPES.csv) {
        this.payloadDataOptions.placeholder = CSV_PLACEHOLDER;
      }

      this.columns = columns;
      this.updateCustomPayload();
    }

    if (changes.isCustomPayload?.currentValue && !changes.isCustomPayload?.firstChange) {
      this.recordsCopy = this.parentSchemas[0]?.fields
        .map((field) => ({
          field_name: field.name,
          column_name: field.name,
          field_type: 'string',
          id: uuidv4(),
        }))
        .slice(0, 1) || [
        {
          field_name: 'value',
          column_name: 'attr',
          field_type: 'string',
          id: uuidv4(),
        },
      ];

      this.onRecordChange({ records: this.recordsCopy });
      this.validityChange.emit(true);
    }

    this.updatePreview();
  }

  ngOnInit() {
    this.recordsCopy = [...(this.records || [])].map((item) => ({
      ...item,
      id: uuidv4(),
    }));
    this.constRecordsCopy = [...(this.rawComponent.json_consts || [])].map((item) => ({
      ...item,
      id: uuidv4(),
    }));
    this.customPayloadRecordsCopy = this.generateFieldsFromCustomPayload().map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 10,
      emptyRecord: {
        field_name: '',
        column_name: '',
        FC_pristine: true,
        field_type: 'string',
      },
      parentSchemas: this.parentSchemas,
      autoGenerateFn: this.autoGenerateAliasAndUpdateRecords.bind(this),
      autoFillFns: [
        {
          func: this.autoFill.bind(this),
          text: 'Auto fill',
          isHidden: true,
        },
      ],
    };

    this.constsCollectionSettings = {
      itemsPerPage: 5,
      emptyRecord: {
        field_name: '',
        column_name: '',
        FC_pristine: true,
        field_type: 'string',
      },
    };

    this.customPayloadCollectionSettings = {
      itemsPerPage: 5,
      emptyRecord: {
        field_name: '',
        column_name: '',
        FC_pristine: true,
      },
    };

    if (this.requestType === REST_API_REQUEST_TYPES.json) {
      this.payloadDataOptions.placeholder = JSON_PLACEHOLDER;
    }

    if (this.requestType === REST_API_REQUEST_TYPES.csv) {
      this.payloadDataOptions.placeholder = CSV_PLACEHOLDER;
    }

    let columns = ['field_name', 'column_name'];

    if (this.requestType === REST_API_REQUEST_TYPES.json) {
      columns = ['field_name', 'column_name', 'field_type'];
    }

    this.columns = columns;

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
    this.updatePreview();
  }

  autoFill(): DesignerSchemaFieldI[] {
    if (!this.parentSchemas[0] || !this.parentSchemas[0]?.fields?.length) return [];
    return this.fillRecords(this.parentSchemas[0].fields);
  }

  fillRecords(fields: DesignerSchemaFieldI[]): DesignerSchemaFieldI[] {
    return fields.map((field) => {
      let fieldName = field.name;

      const fieldData: DesignerSchemaFieldI = {
        field_name: field.name,
        column_name: fieldName,
        field_type: 'string',
        id: uuidv4(),
      };

      return fieldData;
    });
  }

  autoGenerateAliasAndUpdateRecords(record: DesignerSchemaFieldI) {
    const alias = autoGenerateAlias(record);

    if (alias) {
      this.recordsCopy = this.recordsCopy.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            column_name: alias,
          };
        }
        return item;
      });
    }

    this.onRecordChange({ records: this.recordsCopy });
  }

  validateSchemaMapping() {
    let schemaMappingValid = true;

    // checking valid records
    if (this.recordsCopy.find((item) => item.FC_pristine)) {
      schemaMappingValid = false;
    }

    return schemaMappingValid;
  }

  onRecordChange({ records }) {
    this.recordsCopy = records;
    const fields = records.map((record) => ({
      field_name: record.field_name,
      column_name: record.column_name,
      field_type: record.field_type,
    }));

    this.recordsChange.emit(fields);

    this.updatePreview();
  }

  onCustomPayloadRecordChange({ records }) {
    this.customPayloadRecordsCopy = records;

    this.updateCustomPayload();

    this.updatePreview();
  }

  onConstRecordChange({ records }) {
    this.constRecordsCopy = records;
    const fields = records.map((record) => ({
      field_name: record.field_name,
      column_name: record.column_name,
      field_type: record.field_type,
    }));

    this.constsRecordsChange.emit(fields);

    this.updatePreview();
  }
  onConstFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value && this.validateSchemaMapping());
  }

  onCustomPayloadFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value && this.validateSchemaMapping());
  }

  onCustomPayloadFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.customPayloadRecordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.customPayloadRecordsCopy = newRecords;

    this.updateCustomPayload();

    this.updatePreview();
  }

  onConstFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.constRecordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.constRecordsCopy = newRecords;

    this.onConstRecordChange({
      records: newRecords,
    });
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value && this.validateSchemaMapping());
  }

  onValueChange(value: any, key: string) {
    if (key === 'set_field_name' && value) {
      this.onRecordChange({
        records: [this.recordsCopy[0]].map((record) => ({ ...record, column_name: record.field_name })),
      });

      this.updatePreview();
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  updatePreview() {
    if (this.requestType === REST_API_REQUEST_TYPES.json) {
      this.jsonPreview = this.recordsCopy.reduce((acc, record) => {
        acc[record.column_name] = 'value';

        if (record.field_type === 'array') {
          acc[record.column_name] = ['value1', 'value2', 'value3'];
        }

        if (record.field_type === 'object') {
          acc[record.column_name] = {
            key1: 'value1',
            key2: 'value2',
            key3: 'value3',
          };
        }

        if (record.field_type === 'number') {
          acc[record.column_name] = 123;
        }

        if (record.field_type === 'boolean') {
          acc[record.column_name] = true;
        }

        return acc;
      }, {});

      if (this.rawComponent.set_field_name) {
        this.jsonPreview = Object.values(this.jsonPreview)[0];
      }

      this.jsonPreview = this.isBatch ? [this.jsonPreview] : this.jsonPreview;

      if (this.rawComponent.set_field_name) {
        this.jsonPreview = [this.jsonPreview[0], this.jsonPreview[0], this.jsonPreview[0]];
      }

      if (this.rawComponent.send_records_as_nested_attribute) {
        const nestedAttribute = this.rawComponent.nested_attribute_name || 'records';
        this.jsonPreview = {
          [nestedAttribute]: this.jsonPreview,
        };

        this.constRecordsCopy
          .filter((record) => record.field_name)
          .forEach((record) => {
            this.jsonPreview[record.field_name] = record.column_name;

            if (record.field_type === 'array' || record.field_type === 'object') {
              try {
                this.jsonPreview[record.field_name] = JSON.parse(record.column_name);
              } catch (e) {
                try {
                  this.jsonPreview[record.field_name] = JSON.parse(record.column_name.replaceAll("'", '"'));
                } catch (e) {
                  this.jsonPreview[record.field_name] = record.column_name;
                }
              }
            }

            if (record.field_type === 'number') {
              try {
                this.jsonPreview[record.field_name] = Number(record.column_name);
              } catch (e) {
                this.jsonPreview[record.field_name] = record.column_name;
              }
            }

            if (record.field_type === 'boolean') {
              try {
                this.jsonPreview[record.field_name] = Boolean(record.column_name);
              } catch (e) {
                this.jsonPreview[record.field_name] = record.column_name;
              }
            }
          });
      }

      this.formattedPreview = JSON.stringify(this.jsonPreview, null, 4);
    }

    if (this.requestType === REST_API_REQUEST_TYPES.csv) {
      this.tablePreview.fields = this.recordsCopy.map((record) => record.column_name);
      this.tablePreview.items = [1, 2, 3].map(() => ({
        values: this.recordsCopy.map((record, index) => `record-value${index + 1}`),
      }));
    }

    if (this.requestType === REST_API_REQUEST_TYPES.formData) {
      this.formattedPreview = this.convertJsonToMultipartFormData(this.recordsCopy, true);
    }

    if (this.requestType === REST_API_REQUEST_TYPES.urlEncoded) {
      this.formattedPreview = this.recordsCopy
        .map((record) => `${encodeURIComponent(record.column_name)}=${encodeURIComponent('value')}`)
        .join('&');
    }
  }

  convertJsonToMultipartFormData(fields, isExampleValue: boolean): string {
    let preview = '';
    const boundary = '----WebKitFormBoundary' + uuidv4();

    fields.forEach((record) => {
      preview += `--${boundary}\r\n`;
      preview += `Content-Disposition: form-data; name="${record.column_name}"\r\n`;
      preview += `Content-Type: text/plain; charset=UTF-8\r\n\r\n`;
      preview += `${isExampleValue ? 'value' : record.field_name}\r\n`;
    });
    preview += `--${boundary}--\r\n`;
    return preview;
  }

  updateCustomPayload() {
    if (!this.isCustomPayload) {
      return;
    }

    if (this.requestType === REST_API_REQUEST_TYPES.urlEncoded) {
      const customPayload = this.customPayloadRecordsCopy
        .map((record) => `${encodeURIComponent(record.column_name)}=${encodeURIComponent(record.field_name)}`)
        .join('&');

      this.onValueChange(customPayload, 'custom_payload');
    }

    if (this.requestType === REST_API_REQUEST_TYPES.formData) {
      this.onValueChange(this.convertJsonToMultipartFormData(this.customPayloadRecordsCopy, false), 'custom_payload');
    }

    if (this.requestType === REST_API_REQUEST_TYPES.csv) {
      let customPayload;
      customPayload = this.customPayloadRecordsCopy
        .map((record) => (record.column_name ? record.column_name : ''))
        .join(',');
      customPayload += '\n';
      customPayload += this.customPayloadRecordsCopy
        .map((record) => (record.field_name ? record.field_name : ''))
        .join(',');
      this.onValueChange(customPayload, 'custom_payload');
    }

    if (this.requestType === REST_API_REQUEST_TYPES.json) {
      let customPayload = '{\n';
      customPayload += this.customPayloadRecordsCopy
        .map((record) => `  "${record.column_name}": "${record.field_name}"`)
        .join(',\n');
      customPayload = customPayload.slice(0, -2);
      customPayload += '"\n}\n';
      this.onValueChange(customPayload, 'custom_payload');
    }
  }

  generateFieldsFromCustomPayload() {
    if (this.isCustomPayload) {
      return [];
    }
    if (this.requestType === REST_API_REQUEST_TYPES.urlEncoded) {
      try {
        return this.rawComponent.custom_payload.split('&').map((pair) => {
          const [column_name, field_name] = pair.split('=');
          return { column_name: decodeURIComponent(column_name), field_name: decodeURIComponent(field_name) };
        });
      } catch (e) {
        return [];
      }
    }

    if (this.requestType === REST_API_REQUEST_TYPES.formData) {
      try {
        const boundaryMatch = this.rawComponent.custom_payload.match(/^--(.+?)\r?\n/);
        const boundary = boundaryMatch[1];

        const parts = this.rawComponent.custom_payload.split(`--${boundary}`);

        return parts
          .filter((part) => part.trim() !== '' && part.trim() !== `--`)
          .map((part) => {
            const [headers, content] = part.split('\r\n\r\n');

            const columnNameMatch = headers.match(/name="([^"]+)"/);
            const column_name = columnNameMatch ? columnNameMatch[1] : null;

            const field_name = content ? content.trim() : '';

            return { field_name, column_name };
          });
      } catch (e) {
        return [];
      }
    }

    return [];
  }
}
