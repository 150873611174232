import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, of, combineLatest } from 'rxjs';
import { isEmpty } from 'lodash';

import { AppState } from '../../store';
import { selectAccountId, selectAccountIdRaw } from '../../account/store/account.selectors';
import { AnyConnection, ConnectionAccount, GA4PropertiesResponse } from '../connection.models';
import { AllComponentData, SchemaResponse } from '../../package-designer/package.models';
import { DesignerSchemaFieldI } from '../../package-designer/models/designer-schema-field.model';
import { environment } from '../../../environments/environment';
import { CustomHeaders, ListParams, trimObjectValues } from '../../common/helper/query-params-generic-list.helper';
import { selectPackageId } from '../../package-designer/store/package-designer.selectors';

interface QueryParams extends HttpParams {}

@Injectable({
  providedIn: 'root',
})
export class ConnectionItemsResource {
  expressions: { [key: string]: { expression: string; parserResult: any } } = {};
  private apiRoot: string = environment.API_URL;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  clearExpressions() {
    this.expressions = {};
  }

  evaluateInAPI(parserResult: any, variableKey: string, expression: string, nestedKey?: string) {
    let key = variableKey;
    if (nestedKey) {
      key = `${nestedKey}.${variableKey}`;
    }
    this.expressions[key] = { expression, parserResult: parserResult };
  }

  get(type: string, connection_id: number, params?: ListParams, headers?: CustomHeaders): Observable<AnyConnection> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<AnyConnection>(`${this.apiRoot}/${account_id}/api/connections/${type}/${connection_id}`, {
          params: params as QueryParams,
          headers: headers as HttpHeaders,
        }),
      ),
    );
  }

  save(type: string, body: Partial<AnyConnection>, params?: ListParams): Observable<AnyConnection> {
    const trimmedBody = trimObjectValues(body);
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<AnyConnection>(`${this.apiRoot}/${account_id}/api/connections/${type}`, trimmedBody, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  update(
    type: string,
    connection_id: number,
    body: Partial<AnyConnection>,
    params?: ListParams,
  ): Observable<AnyConnection> {
    const trimmedBody = trimObjectValues(body);
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<AnyConnection>(
          `${this.apiRoot}/${account_id}/api/connections/${type}/${connection_id}`,
          trimmedBody,
          { params: params as QueryParams },
        ),
      ),
    );
  }

  remove(type: string, connection_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.delete<AllComponentData>(`${this.apiRoot}/${account_id}/api/connections/${type}/${connection_id}`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  query(type: string, params?: ListParams): Observable<AllComponentData[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<AllComponentData[]>(`${this.apiRoot}/${account_id}/api/connections/${type}`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  schema(type: string, connection_id: number, body: any, params?: ListParams): Observable<SchemaResponse> {
    return combineLatest([this.store.select(selectAccountIdRaw), this.store.select(selectPackageId)]).pipe(
      mergeMap(([account_id, package_id]) => {
        const url =
          connection_id && type !== 'curl'
            ? `${this.apiRoot}/${account_id}/api/connections/${type}/${connection_id}/schema`
            : `${this.apiRoot}/${account_id}/api/connections/${type}/schema`;
        return this.http.post<SchemaResponse>(
          url,
          { ...body, package_id, ...(!isEmpty(this.expressions) && { expressions: this.expressions }) },
          {
            params: params as QueryParams,
            headers: { ignoreLoadingBar: '', ignorenotify: 'true' },
          },
        );
      }),
    );
  }

  test(
    type: string,
    connection_id: number | undefined,
    body: any,
    params?: ListParams,
  ): Observable<AllComponentData[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) => {
        const url = connection_id
          ? `${this.apiRoot}/${account_id}/api/connections/${type}/${connection_id}/test`
          : `${this.apiRoot}/${account_id}/api/connections/${type}/test`;

        return this.http.post<AllComponentData[]>(url, body, {
          params: params as QueryParams,
          headers: { ignoreLoadingBar: '', ignorenotify: 'true' },
        });
      }),
    );
  }

  accounts(type: string, connection_id: number, params?: ListParams): Observable<ConnectionAccount[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<ConnectionAccount[]>(
          `${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/accounts`,
          { params: params as QueryParams, headers: { ignoreLoadingBar: '', ignorenotify: 'true' } },
        ),
      ),
    );
  }

  properties(type: string, connection_id: number, params?: QueryParams): Observable<GA4PropertiesResponse> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<GA4PropertiesResponse>(
          `${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/properties`,
          { params, headers: { ignoreLoadingBar: '', ignorenotify: 'true' } },
        ),
      ),
    );
  }

  objects(type: string, connection_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<any>(`${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/objects`, {
          params: params as QueryParams,
          headers: { ignoreLoadingBar: '', ignorenotify: 'true' },
        }),
      ),
    );
  }

  googleAdsObjects(params?: ListParams): Observable<any> {
    return this.http.get<any>(`${this.apiRoot}//ads/AdsV10.json`, {
      params: params as QueryParams,
      headers: { ignoreLoadingBar: '', ignorenotify: 'true' },
    });
  }

  fields(type: string, connection_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<any>(`${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/fields`, {
          params: params as QueryParams,
          headers: { ignoreLoadingBar: '', ignorenotify: 'true' },
        }),
      ),
    );
  }

  writableFields(
    type: string,
    connection_id: number,
    params?: ListParams,
  ): Observable<{ fields: DesignerSchemaFieldI[] }> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<{ fields: DesignerSchemaFieldI[] }>(
          `${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/writable_fields`,
          { params: params as QueryParams, headers: { ignoreLoadingBar: '', ignorenotify: 'true' } },
        ),
      ),
    );
  }

  relationKeys(
    type: string,
    connection_id: number | string,
    params?: ListParams,
  ): Observable<{ keys: DesignerSchemaFieldI[] }> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<{ keys: DesignerSchemaFieldI[] }>(
          `${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/relation_keys`,
          { params: params as QueryParams, headers: { ignoreLoadingBar: '', ignorenotify: 'true' } },
        ),
      ),
    );
  }

  fieldsAsArray(type: string, connection_id: number, params?: ListParams): Observable<AllComponentData[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<AllComponentData[]>(
          `${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/fields`,
          { params: params as QueryParams, headers: { ignoreLoadingBar: '', ignorenotify: 'true' } },
        ),
      ),
    );
  }

  customers(type: string, connection_id: number, params?: ListParams): Observable<ConnectionAccount[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<ConnectionAccount[]>(
          `${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/customers`,
          { params: params as QueryParams, headers: { ignoreLoadingBar: '', ignorenotify: 'true' } },
        ),
      ),
    );
  }

  ga4Fields(api_version: string = 'v1beta', params?: QueryParams): Observable<GA4Field[]> {
    return this.http.get<GA4Field[]>(`${this.apiRoot}/ga4/${api_version}.json`, {
      params,
      headers: { ignoreLoadingBar: '', ignorenotify: 'true' },
    });
  }

  customFields(type: string, connection_id: number, params?: QueryParams): Observable<ConnectionAccount[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<ConnectionAccount[]>(
          `${this.apiRoot}/${account_id}/api/connections/metadata/${type}/${connection_id}/customfields`,
          { params, headers: { ignoreLoadingBar: '', ignorenotify: 'true' } },
        ),
      ),
    );
  }

  databaseSchemas(
    type: string,
    connection_id: number,
    params?: {
      limit: number;
      offset: number;
      search: string;
    },
  ): Observable<SchemaResponse> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<SchemaResponse>(
          `${this.apiRoot}/${account_id}/api/connections/${type}/${connection_id}/schemas`,
          {
            ...params,
          },
        ),
      ),
    );
  }

  databaseTables(
    type: string,
    connection_id: number,
    params?: {
      schema_name: string;
      limit: number;
      offset: number;
      search: string;
    },
  ): Observable<SchemaResponse> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<SchemaResponse>(
          `${this.apiRoot}/${account_id}/api/connections/${type}/${connection_id}/tables`,
          {
            ...params,
          },
        ),
      ),
    );
  }

  databaseColumns(
    type: string,
    connection_id: number,
    params?: { schema_name: string; table: string },
  ): Observable<SchemaResponse> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<SchemaResponse>(
          `${this.apiRoot}/${account_id}/api/connections/${type}/${connection_id}/schema`,
          {
            ...params,
            lines: 0,
          },
        ),
      ),
    );
  }
}

interface GA4Field {
  dataType: string;
  id: string;
  type: string;
  uiName: string;
}
